import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { MatCalendar, MatCalendarView } from '@angular/material/datepicker';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export enum CalendarView {
  Month = 'month',
  Year = 'year',
}

@Component({
  selector: 'shared-calendar-header',
  templateUrl: './calendar-header.component.html',
  styleUrls: ['./calendar-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarHeaderComponent<D> implements OnDestroy {
  readonly CalendarView = CalendarView;
  private destroyed$ = new Subject<void>();

  constructor(
    private calendar: MatCalendar<D>,
    private dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) private dateFormats: MatDateFormats,
    private cdr: ChangeDetectorRef
  ) {
    calendar.stateChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => cdr.detectChanges());
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  get periodLabel(): string {
    if (this.calendar.currentView === 'month') {
      return this.dateAdapter.format(this.calendar.activeDate, this.dateFormats.display.monthYearA11yLabel);
    } else if (this.calendar.currentView === 'year') {
      return this.dateAdapter.format(this.calendar.activeDate, 'yyyy');
    } else {
      return '';
    }
  }

  get currentView(): MatCalendarView {
    return this.calendar.currentView;
  }

  get hasNextMonth(): boolean {
    if (this.calendar.maxDate != null) {
      return this.calendar.activeDate <= this.calendar.maxDate;
    }
    const today = new Date();
    const activeDate = this.dateAdapter.format(this.calendar.activeDate, this.dateFormats.display.dateInput);
    // test if calendar is in month view
    if (this.calendar.currentView === 'month') {
      const date = new Date(activeDate);
      return date.getMonth() !== today.getMonth() || date.getFullYear() !== today.getFullYear();
    } else if (this.calendar.currentView === 'year') {
      // test if selected year is lower than current year
      return new Date(activeDate).getFullYear() < today.getFullYear();
    } else {
      return false;
    }
  }

  get hasBeforeMonth(): boolean {
    if (this.calendar.minDate == null || this.calendar.activeDate == null) {
      return false;
    }

    // test if calendar is in month view
    if (this.calendar.currentView === CalendarView.Month) {
      if (
        this.dateAdapter.getYear(this.calendar.activeDate) > this.dateAdapter.getYear(this.calendar.minDate) ||
        (this.dateAdapter.getYear(this.calendar.activeDate) === this.dateAdapter.getYear(this.calendar.minDate) &&
          this.dateAdapter.getMonth(this.calendar.activeDate) > this.dateAdapter.getMonth(this.calendar.minDate))
      ) {
        return true;
      }
    } else if (this.calendar.currentView === CalendarView.Year) {
      // test if selected year is greater than this.calendat.minDate
      if (this.dateAdapter.getYear(this.calendar.activeDate) > this.dateAdapter.getYear(this.calendar.minDate)) {
        return true;
      }
    }
    return false;
  }

  previousClicked(): void {
    // get current view
    if (this.calendar.currentView === CalendarView.Month) {
      this.calendar.activeDate = this.dateAdapter.addCalendarMonths(this.calendar.activeDate, -1);
    } else if (this.calendar.currentView === CalendarView.Year) {
      this.calendar.activeDate = this.dateAdapter.addCalendarYears(this.calendar.activeDate, -1);
    }
  }

  nextClicked(): void {
    if (this.calendar.currentView === CalendarView.Month) {
      this.calendar.activeDate = this.dateAdapter.addCalendarMonths(this.calendar.activeDate, 1);
    } else if (this.calendar.currentView === CalendarView.Year) {
      this.calendar.activeDate = this.dateAdapter.addCalendarYears(this.calendar.activeDate, 1);
    }
  }

  viewChangedHandler(): void {
    if (this.calendar.currentView === CalendarView.Month) {
      this.calendar.currentView = CalendarView.Year;
    } else {
      this.calendar.currentView = CalendarView.Month;
    }
    this.cdr.reattach();
  }
}
