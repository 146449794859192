export enum FormatType {
  Text = 'Text',
  Date = 'Date',
  DateWithoutYear = 'DateWithoutYear',
  MonthAndYear = 'MonthAndYear',
  DateTime = 'DateTime', // aka Aug 28, 2023, 9:04:33 AM
  HourMins = 'HourMins', // aka 9:04 AM
  HourMinsSecs = 'HourMinsSecs', // aka 9:04:05 AM
  HourBucket = 'HourBucket', // aka 9 AM - 10 AM
  Time = 'Time', // aka duration
  TimeAgo = 'TimeAgo',
  DaysAge = 'DaysAge',
  Percentage = 'Percentage',
  Number = 'Number',
  NumberHours = 'NumberHours',
  Currency = 'Currency',
  Currency4 = 'Currency4',
  Bytes = 'Bytes',
  Fte = 'Fte',
}
