import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { DefaultMatCalendarRangeStrategy, MatCalendar, MatRangeDateSelectionModel } from '@angular/material/datepicker';
import { DateTime } from 'luxon';
import { DateHelper } from '../../../helpers/date.helper';
import { DataAvailabilityInfo } from '../../../types/data-availability-info.type';
import { DropdownComponent } from '../../dropdown/dropdown.component';
import { CalendarHeaderComponent } from '../calendar-header/calendar-header.component';

@Component({
  selector: 'shared-calendar-dropdown',
  templateUrl: './calendar-dropdown.component.html',
  styleUrls: ['./calendar-dropdown.component.scss'],
  providers: [
    DefaultMatCalendarRangeStrategy,
    MatRangeDateSelectionModel,
    { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: CalendarDropdownComponent },
    { provide: NG_VALIDATORS, multi: true, useExisting: CalendarDropdownComponent },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarDropdownComponent implements OnInit, ControlValueAccessor, Validator {
  @Input() selectedDate: Date;
  @Input() set dataAvailability(value: DataAvailabilityInfo) {
    this._dataAvailability = value;
    this.dateFilter = (date: DateTime) => {
      return this._dataAvailability?.hasDataForDate(date.toJSDate()) ?? true;
    };
  }

  get dataAvailability(): DataAvailabilityInfo {
    return this._dataAvailability;
  }

  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() disabled: boolean;
  @Output() dateChange = new EventEmitter<Date>();

  @ViewChild(MatCalendar) calendar: MatCalendar<DateTime>;
  @ViewChild(DropdownComponent, { static: false }) childRef: DropdownComponent;

  today = DateHelper.startOfToday();
  customCalendarHeader = CalendarHeaderComponent;

  private onChange = (date: Date) => {};
  private onTouched = () => {};
  private onValidatorChange: any = () => {};
  private touched = false;
  private _dataAvailability: DataAvailabilityInfo;

  get selectedDateLabel(): string {
    if (this.selectedDate == null) {
      return 'Select a date';
    }
    return DateHelper.getDayLabel(this.selectedDate);
  }

  get selectedDateTime(): DateTime {
    if (this.selectedDate == null) {
      return null;
    }
    return DateTime.fromJSDate(this.selectedDate);
  }

  get minDateTime(): DateTime {
    return this.minDate != null ? DateTime.fromJSDate(this.minDate) : this.dataAvailability?.firstDate != null ? DateTime.fromJSDate(this.dataAvailability.firstDate) : null;
  }

  get maxDateTime(): DateTime {
    return this.maxDate != null ? DateTime.fromJSDate(this.maxDate) : null;
  }

  dateFilter: (date: DateTime) => boolean;

  constructor(
    private readonly selectionModel: MatRangeDateSelectionModel<DateTime>,
    private readonly selectionStrategy: DefaultMatCalendarRangeStrategy<DateTime>,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.dateChange.emit(this.selectedDate);
  }

  dateChanged(selectedDate: DateTime): void {
    const selection = this.selectionModel.selection;
    const newSelection = this.selectionStrategy.selectionFinished(selectedDate, selection);
    this.markAsTouched();
    this.selectedDate = selectedDate.toJSDate();
    this.selectionModel.updateSelection(newSelection, this);
    this.onChange(this.selectedDate);
    this.dateChange.emit(this.selectedDate);
    this.childRef?.closed.emit();
  }

  writeValue(date: Date): void {
    this.selectedDate = date;
    this.cdr.markForCheck();
  }

  registerOnChange(onChange: (date: Date) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(disabled: boolean): void {
    this.disabled = disabled;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  // Validator implementation
  registerOnValidatorChange?(onValidatorChange: () => void): void {
    this.onValidatorChange = onValidatorChange;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const value: Date = control.value as Date;
    if (value == null) {
      return null;
    }
    if (this.minDateTime != null && DateTime.fromJSDate(value) < this.minDateTime) {
      return { minDate: true };
    }
    if (this.maxDateTime != null && DateTime.fromJSDate(value) > this.maxDateTime) {
      return { maxDate: true };
    }
    return null;
  }
}
