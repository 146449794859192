import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { StatusType } from '../../../modules/monitoring/status-type.enum';
import { TextHelper } from '../../helpers/text.helper';
import { PlatformTextsService } from '../../services/platform-texts.service';

@Component({
  selector: 'shared-status-info',
  templateUrl: './status-info.component.html',
  styleUrls: ['./status-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusInfoComponent {
  private _status: StatusType;
  iconKey: string;
  color: string;

  @Input() set status(status: StatusType) {
    this._status = status;
    switch (status) {
      case StatusType.Running:
      case StatusType.Stopping:
      case StatusType.Terminating:
      case StatusType.Resumed:
        this.iconKey = 'uploaded';
        this.color = 'success';
        break;
      case StatusType.Debugging:
      case StatusType.Pending:
      case StatusType.VirtualPending:
        this.iconKey = 'time';
        this.color = 'default';
        break;
      case StatusType.Success:
      case StatusType.Completed:
        this.iconKey = 'done-circle';
        this.color = 'success';
        break;
      case StatusType.Failed:
      case StatusType.NotResponding:
      case StatusType.Warning:
        this.iconKey = 'error-circle';
        this.color = 'danger';
        break;
      case StatusType.Stopped:
      case StatusType.Terminated:
      case StatusType.Suspended:
        this.iconKey = 'error-circle';
        this.color = 'warning';
        break;
      case StatusType.VirtualCanceling:
        this.iconKey = 'close';
        this.color = 'gray';
        break;
      case StatusType.Archived:
      default:
        this.iconKey = 'unknown';
        this.color = 'default';
        break;
    }
  }

  get status(): StatusType {
    return this._status;
  }

  get statusText(): string {
    return TextHelper.enumToText(this.platformTexts.get(this.status));
  }

  constructor(private platformTexts: PlatformTextsService) {}
}
